import styled from 'styled-components';
import { useState } from 'react';
import Typography from '../../../components/common/text/Typography';
import DiamondBackground from '../../../assets/images/backgrounds/Diamond_Checkered_Background.png';
import Button from '../../../components/common/button/Button';
import { setColor } from '../../../assets/styles/styles';
import { Input } from '../../../components/common/input/Input';

const PasswordProtectMain = ({ setAllow }) => {
    const [password, setPassword] = useState('');
    const [passError, setPassError] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = checkPassword(password);
        if (isValid) {
            console.log('Password is correct');
            setPassError(false);
            setAllow(true);
        } else {
            console.log('Password is incorrect');
            setMessage('Password is incorrect');
            setPassError(true);
        }
    };

    const checkPassword = (password) => {
        const storedPass = process.env.REACT_APP_PROD_PASSWORD;
        if (storedPass?.toString() === password.trim()) {
            return true;
        }
        return false;
    };

    return (
        <FullScreenContainer>
            <Typography align={'center'} variant="ConsoleTitle">
                Intraverse is coming soon!
            </Typography>
            <PasswordBox onSubmit={handleSubmit}>
                <Input
                    variant="LoginInput"
                    type="password"
                    placeholder="Enter TerraZero Staff Password"
                    value={password}
                    onChangeHandler={(e) => setPassword(e.target.value)}
                    errorMessage={passError ? message : ''}
                    style={{ height: '60px', width: '100%' }}
                />
                <Button
                    type="submit"
                    label={'Enter'}
                    height="60px"
                    width="100%"
                    variant="PinkButton"
                    style={{ marginTop: '2rem' }}
                />
            </PasswordBox>
        </FullScreenContainer>
    );
};

export default PasswordProtectMain;

const FullScreenContainer = styled.div`
    z-index: 2000;
    display: flex;
    justify-content: flex-start;
    padding-top: 4rem;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: url(${DiamondBackground}) cover;
`;
const PasswordBox = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${setColor.boxShadowContainer};
    height: fit-content;
    width: 350px;
    background-color: transparent;
    padding: 1rem;
`;
